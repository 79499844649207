// This is the scss entry file
// import "../styles/index.scss";

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
// import "bootstrap/dist/js/bootstrap.bundle";
import "htmx.org";
import 'flowbite';
import 'preline';

window.htmx = require("htmx.org");

import Alpine from "alpinejs";
import focus from "@alpinejs/focus";

import collapse from "@alpinejs/collapse";
import Clipboard from "@ryangjchandler/alpine-clipboard";
// eslint-disable-next-line no-unused-vars
import Toastify from 'toastify-js';

window.Toastify = Toastify;


// eslint-disable-next-line no-unused-vars
import overlay from "@preline/overlay";
// eslint-disable-next-line no-unused-vars
import tabs from "@preline/tabs";

// eslint-disable-next-line no-unused-vars
import HSOverlay from "@preline/overlay";

window.HSOverlay = HSOverlay;

// eslint-disable-next-line no-unused-vars
import AirDatepicker from "air-datepicker";
import '../styles/air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';


window.AirDatepicker = AirDatepicker;
window.localeEn = localeEn;







// eslint-disable-next-line no-unused-vars
import accordion from "@preline/accordion";

// eslint-disable-next-line no-unused-vars
import select from "@preline/select";




Alpine.plugin(focus);
Alpine.plugin(collapse);
function toast(text){
    Toastify({text: text,
                  duration: 3000,

                  close: true,
                  gravity: "top",
                  position: "right",
                  stopOnFocus: false,
                  className: "flex items-center max-w-sm py-5 px-6 text-gray-600 bg-white rounded-xl border border-gray-200 shadow-sm mb-4",


                  onClick: function(){} // Callback after click
                }).showToast();
  }
Alpine.plugin(Clipboard.configure({
    onCopy: () => {
        toast('Copied!');
    }
}));


window.Alpine = Alpine;

Alpine.start();

// We can import other JS file as we like
import "../components/sidebar";


console.log("been read");











